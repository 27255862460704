<template>
  <div class="main">
    <banner-swiper class="other">
      <swiper-slide>
        <div class="banner-cover" />
        <div class="banner-desc other">
          <div class="icon" />
          <div class="title">媒体变现</div>
          <div class="text">支持主流媒体类型和接入形式<br>让互联网媒体轻松获得高效率变现</div>
        </div>
      </swiper-slide>
      <div slot="after" class="english-run">FEYSOS</div>
    </banner-swiper>
    <ul class="introduction-list wrap">
      <li v-scroll-toggle="['enter']">
        <div class="cover">
          <div class="img-scale">
            <img src="@/assets/introduction_media_01.jpg" alt="">
          </div>
        </div>
        <div class="desc">
          <div class="cn">支持主流媒体类型</div>
          <div class="en">Support mainstream media types</div>
          <p>媒体只需要负责用户体验，我们负责帮您商业变现。</p>
        </div>
      </li>
      <li v-scroll-toggle="['enter']">
        <div class="cover">
          <div class="img-scale">
            <img src="@/assets/introduction_media_02.jpg" alt="">
          </div>
        </div>
        <div class="desc">
          <div class="cn">支持主流接入形式</div>
          <div class="en">Support mainstream access forms</div>
          <p>选择适合您的方式快速接入，任何形式都在1小时内可完成对接。</p>
        </div>
      </li>
      <li v-scroll-toggle="['enter']">
        <div class="cover">
          <div class="img-scale">
            <img src="@/assets/introduction_media_03.jpg" alt="">
          </div>
        </div>
        <div class="desc">
          <div class="cn">一对一媒介经理服务</div>
          <div class="en">One to one media manager services</div>
          <p>搭配高级变现优化师和经验丰富的产品团队支持。</p>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import BannerSwiper from '@/components/bannerSwiper'

import scrollToggle from '@/directives/scrollToggle'

export default {
  name: 'Media',
  components: {
    BannerSwiper
  },
  directives: {
    scrollToggle
  }
}
</script>

<style lang="scss" scoped>
.banner-container{
  .banner-cover{
    background-image: url('../../assets/banner_media.jpg');
  }
}
</style>
